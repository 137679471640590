import { faBook, faUserTie, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Card from "./Home/Card";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";

const Home = () => {
  const navigate = useNavigate();

  const topMenu = {
    display: "flex",
    justifyContent: "center"
  }

  const logout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    window.location.reload();
  }

  useEffect(() => {
    if(!localStorage.getItem("userId")) {
      navigate("/login");
    }
  })

  if(localStorage.getItem("userId")) {
    return (
      <div>
        <div style={topMenu}>
          <Link to="/chronology" style={{display: "inherit", textDecoration: "none"}}><Card name="年表" icon={faBook} /></Link>
          <Link to="/person" style={{display: "inherit", textDecoration: "none"}}><Card name="人物" icon={faUserTie} /></Link>
          <Link to="/location" style={{display: "inherit", textDecoration: "none"}}><Card name="場所" icon={faLocationDot} /></Link>
        </div>
        <div class="buttonDef" onClick={logout}>ログアウト</div>
      </div>
    )
  }
}

export default Home;