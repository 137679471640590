import { useForm } from 'react-hook-form';

const Insert = () => {
	const { register, handleSubmit } = useForm();
	const onSubmit = (data) => console.log(data);

	return (
		<div style={{marginBottom:15}}>
			<h2>出来事の登録</h2>
			<form id='insertEventForm'>
				<input type='text' {...register('insertEvent')}></input>
				<div>
					<input id='insertEvent-year' type='tel' maxLength={4} width={'10px'} {...register('insertEvent-year')}></input>年
				</div>
				<button>登録</button>
			</form>
    </div>
	);
}

export default Insert;