import { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const useLocalStorage = () => {
    const [userId, setUserId] = useState(localStorage.getItem("userId") || "");
    const [userName, setUserName] = useState(localStorage.getItem("userName") || "");
  
    const saveData = (userId, userName) => {
      localStorage.setItem("userId", userId);
      localStorage.setItem("userName", userName);
    };
  
    const removeData = () => {
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
    };
  
    return { userId, userName, setUserId, setUserName, saveData, removeData };
};

const Register = () => {
    const [inputUserName, setInputUserName] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [inputConfirmPassword, setInputConfirmPassword] = useState('');
    const [authMessage, setAuthMessage] = useState('');
    const { userId, userName, setUserId, setUserName, saveData } = useLocalStorage();
    const navigate = useNavigate();

    useEffect(() => {
        if(userId !== "") {
            navigate("/");
        }
    });

    const rootStyle = {
        maxWidth: 500,
        width: "100%",
        margin: "0 auto 0 auto"
    }

    const handleRegister = async () => {

        await axios.post("/api/register", {
            userName: inputUserName,
            password: inputPassword,
            confirmPassword: inputConfirmPassword
        })
        .then((res) =>  {
            if(res.data.auth === "ok") {
                setUserId(res.data.userdata.id);
                setUserName(res.data.userdata.name);
                saveData(res.data.userdata.id, res.data.userdata.name);
                window.location.reload()
            } else {
                setAuthMessage(res.data.auth);
            }
        });
        };

    return (
        <div style={rootStyle}>
            <h2 onClick={() => {
                console.log(userName)
            }}>ユーザー登録</h2>
            <form class="loginForm">
                <input
                    class="inputDef"
                    type="text"
                    placeholder="ユーザー名"
                    value={inputUserName}
                    onChange={(e) => setInputUserName(e.target.value)}
                />
                <input
                    class="inputDef"
                    type="password"
                    placeholder="パスワード"
                    value={inputPassword}
                    onChange={(e) => setInputPassword(e.target.value)}
                />
                <input
                    class="inputDef"
                    type="password"
                    placeholder="パスワードの確認"
                    value={inputConfirmPassword}
                    onChange={(e) => setInputConfirmPassword(e.target.value)}
                />
                <div>{authMessage}</div>
                <div class="buttonDef loginButton" onClick={handleRegister}>
                    登録
                </div>
            </form>
            <div style={{fontSize:14,marginTop:10,cursor:'pointer',textAlign:'right'}} onClick={() => {navigate('/login')}}>ログインはこちら</div>
        </div>
    );
}

export default Register;