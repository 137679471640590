import './App.css';
import Home from './App/Home';
import Chronology from './App/Home/Chronology';
import Person from './App/Home/Person';
import PersonRegistration from './App/Home/Person/Registration';
import Login from './App/Home/Login';
import Register from './App/Home/Register';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"

const App = () => {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/chronology' element={<Chronology />} />
          <Route path='/person' element={<Person />} />
          <Route path='/person/registration' element={<PersonRegistration />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
