import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = (props) => {
  const cardStyle = {
    width: "200px",
    height: "200px",
    backgroundColor: "#fafafa",
    borderRadius: "20px",
    margin: "10px",
    cursor: "pointer",
    color: "#000"
  }

  const cardNameStyle = {
    textAlign: "center",
    fontSize: "18px",
    padding: "30px",
    fontWeight: "600"
  }

  const iconStyle ={
    display: "block",
    margin: "30px auto 15px auto",
    width: "30%",
    height: "30%"
  }

  return (
    <div style={cardStyle}>
       <FontAwesomeIcon style={iconStyle} icon={props.icon} />
       <div style={cardNameStyle}>{props.name}</div>
    </div>
  )
}

export default Card;