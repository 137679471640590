import React from 'react';
import { useState, useEffect } from 'react';
import axios from "axios";
import './chronology.css';
import Insert from './Chronology/Insert';

import { 
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const Chronology = () => {
  const [events, setEvents] = useState([]);
  useEffect(() => {// /api/chronology
    axios.get("/api/chronology").then((response) => {
      setEvents(response.data.events);
    });
  }, []);

  return (
    <div style={{width: "80%"}}>
      <Insert/>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>出来事</TableCell>
              <TableCell align="right">年</TableCell>
              <TableCell align="right">作成者</TableCell>
              <TableCell align="right">作成日</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event) => (
              <TableRow class="ch-row" key={event.eventId}>
                <TableCell component="th" scope="row">
                  {event.eventText}
                </TableCell>
                <TableCell align="right">{year(event.event_at)}</TableCell>
                <TableCell align="right">{event.created_by}</TableCell>
                <TableCell align="right">{event.created_at}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

function year(date) {
  return date.split("-")[0];
}

export default Chronology; 