import React from 'react';
import { faPenFancy, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Card from "./Card";

const Person = () => {
	const homeStyle = {
    display: "flex",
    justifyContent: "center"
  }

	return (
			<div style={homeStyle}>
				<Link to="/person/registration" style={{display: "inherit", textDecoration: "none"}}><Card name="登録 ・ 編集" icon={faUserTie} /></Link>
      	<Link to="/person/history" style={{display: "inherit", textDecoration: "none"}}><Card name="人物史" icon={faPenFancy} /></Link>
			</div>
	);
}

export default Person;
