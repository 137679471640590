import { useForm } from 'react-hook-form';
import './style.css'

const Registration = () => {
	const { register, handleSubmit } = useForm();
	const onSubmit = (data) => console.log(data);

	return (
		<div id='person-registration'>
			<h2 style={{marginTop:0}}>人物登録</h2>
			<form>
				<div>
					<label class="form-section" htmlFor='person-name'>名前</label>
					<input type='text' id='person-name' {...register('person-name')}></input>
				</div>
				<p class='note'>
					苗字と名前の間は半角スペースを1つ開けてください。<br></br>
					同一人物の登録には十分気をつけてください。
				</p>

				<div style={{display:'flex'}}>
					<div style={{marginRight:'90px'}}>
						<label class="form-section" htmlFor='person-birth'>生誕</label>
						<input id='person-birth' type='tel' maxLength={4} width={'10px'} {...register('person-birth')}></input>年
					</div>
					<div>
						<label class="form-section" htmlFor='person-dead'>死没</label>
						<input id='person-dead' type='tel' maxLength={4} width={'10px'} {...register('person-dead')}></input>年
					</div>
				</div>
				<p class='note'>年が不明な場合は空欄にしてください。</p>

				<div>
					<label class="form-section" htmlFor='person-sex'>性別</label>
					<div>
						<input name='sex' type='radio' id='person-sex-man' {...register('person-sex-man')} checked></input><label for='person-sex-man'>男性</label><br></br>
						<input name='sex' type='radio' id='person-sex-woman' {...register('person-sex-woman')}></input><label for='person-sex-woman'>女性</label>
					</div>
				</div>

				<div>
					<label class="form-section" htmlFor='person-profile'>説明</label>
					<textarea maxLength="60" rows="3" spellCheck="false" id='person-profile' {...register('person-profile')}></textarea>
				</div>

				<div>
					<button id='person-register'>登録</button>
				</div>
			</form>
		</div>
	);
}

export default Registration;